import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  Col,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import { useLocation } from "react-router";
const NavMenu = (props) => {
  const urlPath = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const menuToggle = () => {
    if (window.innerWidth < 768) {
      setIsOpen(!isOpen);
    }
  };
  return (
    <div className="vs-header header-layout3">
      <div className="header-top d-none d-lg-block">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <div className="header-links">
                <ul>
                  <li>
                    <i className="fas fa-phone"></i>Call for any information:{" "}
                    <a
                      href={`tel:${
                        checkData(props.tempObj) && props.tempObj.phone
                      }`}
                    >
                      {checkData(props.tempObj) && props.tempObj.phone}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-auto">
              <div className="header-links">
                <span className="header-links-title">Follow Us:</span>
                <ul>
                  <li>
                    <a href={props.tempObj.facebook} target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.tempObj.twitter_url} target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.tempObj.linkedin} target="_blank">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  {/* <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li> */}
                </ul>
              </div>
            </div>
            <Col className="d-none d-lg-block">
              <div className="header-button">
                {/* <button type="button" className="searchBoxToggler simple-icon">
                            <i className="fas fa-search"></i>
                        </button> */}
                <Link to="/register" className="btn-grad d-none d-xl-block">
                  Register
                </Link>
                {/* <button type="button" className="sideMenuToggler custom-icon">
                            <span className="three-square"></span><span className="three-square"></span><span className="three-square"></span>
                        </button> */}
              </div>
            </Col>
          </div>
        </div>
      </div>
      <div className="container navmenu">
        <Navbar color="light" light expand="md" className="py-0">
          <Col className="main-menu col-md-2">
            <NavbarBrand href="/">
              <div className="header-logo-right">
                <a href="https://www.institutionofvaluers.net" target="_blank">
                  <img
                    src={props.tempObj.path + "/" + props.tempObj.logo}
                    alt="IOVIVC"
                  />
                </a>
              </div>
            </NavbarBrand>
          </Col>
          <NavbarToggler onClick={toggle} />
          <Col className="main-menu col-12 col-md-8">
            <Collapse className="justify-content-center" isOpen={isOpen} navbar>
              <Nav classNameName="ml-auto" navbar>
                <NavItem onClick={menuToggle}>
                  <Link
                    className={`nav-link ${
                      urlPath.pathname === "/" ? "active" : ""
                    }`}
                    to="/"
                  >
                    HOME
                  </Link>
                </NavItem>
                <NavItem
                  className="menu-item-has-children"
                  
                >
                  <Link
                    className={`nav-link ${
                      urlPath.pathname === "/page/about-us" ||
                      urlPath.pathname === "/page/about-iov" ||
                      urlPath.pathname === "/page/about-iov" ||
                      urlPath.pathname === "/page/about-ivc" ||
                      urlPath.pathname === "/page/theme-of-55th-ivc" ||
                      urlPath.pathname === "/page/thematic-issues" ||
                      urlPath.pathname === "/page/about-kochi" ||
                      urlPath.pathname ===
                        "/page/conference-advisory-group-of-iov"
                        ? "active"
                        : ""
                    }`}
                    to="/page/about-us"
                  >
                    About
                  </Link>
                  <ul className="sub-menu">
                    <li onClick={menuToggle}>
                      <Link className={`nav-link nav-sub`} to="/page/about-iov">
                        About IOV
                      </Link>
                    </li>
                    <li onClick={menuToggle}>
                      <Link className={`nav-link nav-sub`} to="/page/about-ivc">
                        About IVC
                      </Link>
                    </li>
                    <li onClick={menuToggle}>
                      <Link className={`nav-link nav-sub`} to="/page/eminent-speakers">
                       Eminent Speakers
                      </Link>
                    </li>
                    <li onClick={menuToggle}>
                      <Link
                        className={`nav-link nav-sub`}
                        to="/page/theme-of-55th-ivc"
                      >
                        Theme of 55th IVC
                      </Link>
                    </li>
                    <li onClick={menuToggle}>
                      <Link
                        className={`nav-link nav-sub`}
                        to="/page/thematic-issues"
                      >
                        Thematic Issues
                      </Link>
                    </li>
                    <li onClick={menuToggle}>
                      <Link
                        className={`nav-link nav-sub`}
                        to="/page/conference-advisory-group-of-iov"
                      >
                        Conference Advisory Group of IOV
                      </Link>
                    </li>
                    <li onClick={menuToggle}>
                      <Link
                        className={`nav-link nav-sub`}
                        to="/page/about-kochi"
                      >
                        About Kochi
                      </Link>
                    </li>
                  </ul>
                </NavItem>
                <NavItem onClick={menuToggle}>
                  <Link
                    className={`nav-link ${
                      urlPath.pathname === "/events" ? "active" : ""
                    }`}
                    to="/events"
                  >
                    Event schedule
                  </Link>
                </NavItem>
                <NavItem onClick={menuToggle}>
                  <Link
                    className={`nav-link ${
                      urlPath.pathname === "/event/gallery" ? "active" : ""
                    }`}
                    to="/event/gallery"
                  >
                    Gallery
                  </Link>
                </NavItem>
                <NavItem onClick={menuToggle}>
                  <Link
                    className={`nav-link ${
                      urlPath.pathname === "/contact" ? "active" : ""
                    }`}
                    to="/contact"
                  >
                    Contact
                  </Link>
                </NavItem>
                <NavItem onClick={menuToggle}>
                  <Link
                    className={`nav-link ${
                      urlPath.pathname === "/page/partners" ? "active" : ""
                    }`}
                    to="/page/partners"
                  >
                    Partners
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </Col>
          <Col className="main-menu col-md-2 d-none d-md-block">
            <NavbarBrand href="/" className="m-0">
              <div className="header-logo">
                <a href="/">
                  <img
                    src={
                      props.tempObj.path + "/" + props.tempObj.top_right_logo
                    }
                    alt="IOVIVC"
                  />
                </a>
              </div>
            </NavbarBrand>
          </Col>
        </Navbar>
      </div>
    </div>
  );
};

export default NavMenu;
