import React, { useState, useEffect } from "react";
import http from "../../CommonAxios";
import { Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'; // Import Modal components
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const CmsPages = () => {
  let cmsId = new URLSearchParams(window.location.search).get("s");
  const [apiData, setApiData] = useState({});
  const [spakerData, setSpeakerData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [detailsModal, setDetailsmodal] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState(null); // For storing selected speaker data
  const [bgImage, setBgImage] = useState(false);
  const { slug } = useParams();
  console.log('spakerData', spakerData)
  const toggleDetails = (title) => {
    setDetailsmodal(!detailsModal);
    setSelectedSpeaker(null);
  };




  const onApiInteg = () => {
    let data = { admin_id: 1, slug: slug };
    setLoader(true);
    http
      .post(`pages/view`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          setApiData(res.data.data);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    // Set background image based on the slug
    const bannerImages = {
      "about-us": "About-Us-Banner.jpg",
      "partners": "Partners-Banner.jpg",
      "about-iov": "About-IOV-Banner.jpg",
      "about-ivc": "About-IVC-Banner.jpg",
      "theme-of-55th-ivc": "Theme-of-55th-IVC-Banner.jpg",
      "thematic-issues": "Thematic-issues.jpg",
      "conference-advisory-group-of-iov": "Conference-Advisory-Group-of-IOV-banner.jpg",
      "about-kochi": "About-Kochi.jpg",
      "privacy-policy": "Privacy-Policy-Banner.jpg",
      "terms-and-condition": "Terms-and-Condition-Banner.jpg",
      "tandc-registration": "Terms-and-Condition-for-Registration.jpg",
      "travel-desk": "Travel-Desk-Banner.jpg",
      "eminent-speakers": "Eminent-Speakers.jpg",
      default: "breadcrumb-bg.jpg"
    };
    setBgImage(bannerImages[slug] || bannerImages.default);

    onApiInteg();
    window.scrollTo(0, 0);
  }, [slug]);

  const onSpeakerApi = () => {
    let data = { admin_id: 1 };
    setLoader(true);
    http.post(`/speaker/front/list`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          setSpeakerData(res?.data?.data);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (slug === "eminent-speakers") {
      onSpeakerApi();
    }
  }, [slug]);

  const handleSelectSpeaker = (title) => {
    const selectedSpeaker = spakerData.find(speaker => speaker.title.trim() === title.trim());

    console.log('selectedSpeaker', selectedSpeaker)
    if (selectedSpeaker) {
      setSelectedSpeaker(selectedSpeaker);
    } else {
      console.error('Speaker not found');
    }
  };

  const handleClick = (event) => {
    const clickedTitle = event.target.textContent;
    handleSelectSpeaker(clickedTitle);
    setDetailsmodal(!detailsModal);
    console.log('Product Title clicked:', clickedTitle);
  };
  useEffect(() => {
    if (slug === "eminent-speakers") {
      const titles = document.querySelectorAll('.product-title');

      titles.forEach(title => {
        title.addEventListener('click', handleClick);
      });
    }
    // return () => {
    //   // Cleanup to remove the event listeners when the component unmounts
    //   titles.forEach(title => {
    //     title.removeEventListener('click', handleClick);
    //   });
    // };
  }, [apiData]);
  return (
    <div className="element">
      <div className="breadcumb-wrapper background-image">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/img/${bgImage}`}
          alt="banner"
          className="w-100 img-fluid"
        />
      </div>
      {checkData(apiData) ? (
        <section className="space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 align-self-center">
                <div className="title-area text-sm-start text-center mb-30">
                  {/* <span className="sub-title">Become Successful Today!</span> */}
                  {/* <h1>{checkUndeNullBlank(apiData[0].title) ? apiData[0].title : ''}</h1> */}
                </div>
                <p className="mb-40 text-sm-start text-center">
                  {checkUndeNullBlank(apiData[0].long_desc)
                    ? parse(apiData[0].long_desc)
                    : ""}
                </p>

                {/* <Button color="primary" className="btn-grad border-0" onClick={() => toggleDetails(2)}>View Details</Button> */}
              </div>
            </div>
          </div>

          {/* Modal for showing details */}
          <Modal isOpen={detailsModal} toggle={toggleDetails} className="speaker-details">
            <ModalHeader toggle={toggleDetails}>
              {selectedSpeaker?.title || "Speaker Details"}
            </ModalHeader>
            <ModalBody>
              {selectedSpeaker ? (
                <>
                  <img src={'https://iovivc.com/api/img/speakers/' + selectedSpeaker.image} alt={selectedSpeaker.title} className="img-fluid mb-3" />
                  <p><strong>{selectedSpeaker.designation}</strong></p>
                  <p>{parse(selectedSpeaker.long_desc || "")}</p>
                </>
              ) : (
                <p>Speaker data not found</p>
              )}
            </ModalBody>
          </Modal>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};

export default CmsPages;
